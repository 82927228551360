<template>
  <div class="stipendiatContacts">
    <button class="stipendiatContacts__btn">Добавить</button>
    <div class="stipendiatContacts__container">
      <ContactEditRights
        :isMain="true"
      />
      <ContactSimple
        :isMain="false"
      />
    </div>
  </div>
</template>

<script>
// import ContactComponent from "@/components/inedComponents/ContactComponent";
import ContactSimple from "@/components/contacts/ContactSimple";
import ContactEditRights from "@/components/contacts/ContactEditRights";

export default {
  name: "StipendiatSborContacts",
  components: {ContactEditRights, ContactSimple}, //ContactComponent
  data() {
    return {
      isMain: false,
    }
  }
}
</script>

<style scoped>

</style>