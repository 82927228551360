import rules from "@/utils/rules";

export default [
  {
    name: 'Фамилия, имя, отчество',
    rules: [rules.required, rules.counter, rules.fam],
    type: 'input',
    typeInput: 'text',
    disabled: false,
    dataField: 'fio',
  },
  {
    name: 'Должность',
    rules: [rules.required, rules.counter3, rules.dolg, rules.fam],
    type: 'input',
    disabled: false,
    dataField: 'position',
  },
  {
    name: 'Электронная почта',
    rules: [rules.required, rules.email],
    type: 'input',
    cols: 12,
    disabled: false,
    dataField: 'email',
  },
  {
    name: 'Рабочий телефон (с кодом города)',
    rules: [rules.required],
    type: 'telephone',
    cols: 6,
    disabled: false,
    dataField: 'workPhoneNumber',
  },
  {
    name: 'Мобильный телефон',
    type: 'telephone',
    cols: 6,
    disabled: false,
    dataField: 'mobilePhoneNumber',
  },
  // {
  //   name: 'Адрес',
  //   type: 'input',
  //   typeInput: 'text',
  //   disabled: false,
  //   dataField: 'address',
  // },
]
