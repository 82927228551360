import axios from 'axios';
import mapper from '@/services/mapper';

/**
 * [request description]
 * @param {Object} options содержит свойства endpoint, method, body, path, model, headers
 * @return {Object} возвращает promise
 * */

async function request(options) {
  const {endpoint, method, body, path, model, headers, token} = options;
  let response;
  try {
    if (method.toLowerCase() === 'get') {
      response = await axios.get(endpoint, {headers: {...headers, Authorization: `Bearer   ${token}`}});
    } else if (method.toLowerCase() === 'post') {
      if (headers) {
        response = await axios.post(endpoint, body, {headers: {...headers, Authorization: `Bearer   ${token}`}});
      } else {
        response = await axios.post(endpoint, body, {headers: {Authorization: `Bearer   ${token}`}});
      }
    } else if (method.toLowerCase() === 'put') {
      if (headers) {
        response = await axios.put(endpoint, body, {headers: {...headers, Authorization: `Bearer   ${token}`}});
      } else {
        response = await axios.put(endpoint, body, {headers: {Authorization: `Bearer   ${token}`}});
      }
    } else if (method.toLowerCase() === 'delete') {
      if (headers) {
        response = await axios.delete(endpoint, {headers: {...headers, Authorization: `Bearer   ${token}`}});
      } else {
        response = await axios.delete(endpoint, {headers: {Authorization: `Bearer   ${token}`}});
      }
    }
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    // Если нужно маппить
    if (model) {
      let formatData;
      if (path) {
        formatData = await mapper(response.data[path], model);
      } else {
        formatData = await mapper(response.data, model);
      }
      const counterData = response.data.counter;
      return {data: formatData, counter: counterData};
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    return {error: e}
  }
}

export class FetchError extends Error {
  name = '';

  constructor(response, body, message, status) {
    super(message);
    this.response = response;
    this.body = body;
    this.status = status;
  }
}

export default request;