<template>
  <LayoutSingle
    :header="true"
    :footer="true"
    :title="layoutTitle"
    :editMode.sync="editMode"
    :max-width="1303"
    @send-data="sendData"
    @delete-data="deleteData"
  >
    <template #contactComponent>
      <!--              :addNotification="addNotification"-->
      <div style="display: flex; flex-direction: row">
        <Contact
          :config="config"
          :header="'Контактная информация представителя центра ответственности, ответственного за формирование предложений о корректировке проекта контрольных цифр приёма.'"
          :contact="contactObject"
          @getContact="getContact"
          :setContact="changeContact"
          :isAdminGroup="false"
          :isDisabledEnter="false"
          :isMain="isMain"
          :updatedData="updatedData"
          :editMode.sync="editMode"
          :arg1="endpointID"
          :arg2="userGroup"
          style="align-self: center"
        />
        <div class="stipendiatContacts__checkbox-container" style="margin-top: 15px;">
          <v-checkbox
            off-icon
            v-for="(type, j) in computedFunc" :key="j"
            :disabled="!editMode"
            v-model="type.value"
            :label="type.name"
          ></v-checkbox>
        </div>
      </div>
    </template>
  </LayoutSingle>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import {Contact, LayoutSingle} from "@frontenddevelopers/ined-components/src/lib";
import contacts from "@/utils/contacts";

export default {
  name: "ContactSimple",
  components: {Contact, LayoutSingle},
  props: {
    isMain: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    layoutTitle: {
      type: String,
      default: '',
    },
    contactObject: {
      type: Object,
      default: function () {
        return {}
      },
    },
    contestTypesSpr: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  
  data() {
    return {
      config: contacts,
      updatedData: false,
      otherContacts: [],
      val1: false,
      spr: null
    }
  },
  
  computed: {
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
    ...mapGetters('keycloak', {getFakeUser: 'getFakeUser'}),
    
    computedFunc() {
      let spr = [];
      for (let a = 0; a < this.contestTypesSpr.length; a++) {
        if (this.contactObject.stpContestNameses?.find(el => el.name === this.contestTypesSpr[a].name)) {
          spr.push({...this.contestTypesSpr[a], value: true})
        } else {
          spr.push({...this.contestTypesSpr[a], value: false})
        }
      }
      return spr;
    },
    
    endpointID() {
      if (this.getAuthData.realmAccess.roles.includes('ORGANIZATION_ROLE')) {
        return this.getAuthData.tokenParsed.orgID
      } else if (this.getAuthData.realmAccess.roles.includes('FOUNDER_ROLE')) {
        return this.getAuthData.tokenParsed.founderID
      } else if (this.getFakeUser?.id) {
        return this.getFakeUser.id
      } else {
        return null
      }
    },
    
    userGroup() {
      if (this.getAuthData.realmAccess.roles.includes('ORGANIZATION_ROLE')) {
        return "orgID"
      } else if (this.getAuthData.realmAccess.roles.includes('FOUNDER_ROLE')) {
        return "founderID"
      } else {
        return "orgID"
      }
    }
  },
  
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    // ...mapActions('notifications', {clearNotifications: 'clearNotifications'}),
    
    async changeContact(obj, userId, userGroup) {
      let uri = `/api/cms/contacts/set`
      const res = await request({
        endpoint: uri,
        method: 'post',
        token: `${this.getAuthData.token}`,
        body: {
          ...obj,
          "userId": userId,
          "userGroup": userGroup,
          "isMain": this.isMain,
          "stpContestNameses": this.computedFunc.filter(el => el.value === true)
        },
      })
      if (res.status === 200 && res.data) {
        // this.mainContact = res.data
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного должностного лица высшего органа исполнительной власти',
          timeout: 5000
        })
      }
    },
    
    async getContact() {
      this.$emit("getContact");
    },
    
    sendData(e) {
      this.updatedData = e;
    },
    
    deleteData() {
      this.$emit('delete-data')
    },
  },
}
</script>

<style lang="scss">

</style>