export default {
  required: value => !!value || 'Обязательное поле!',
  counter: value => value && value.length >= 8 || 'Поле может содержать не меньше 8 символов',
  inn: value => value && value.length === 10 || 'Поле должно содержать 10 символов',
  kpp: value => value && value.length === 9 || 'Поле должно содержать 9 символов',
  counter3: value => value && value.length >= 2 || 'Поле не может содержать меньше 2 символов',
  dolg: value => value && value.length >= 5 || 'Поле не может содержать меньше 5 символов',
  fam: v => {
    const pattern = /[a-zA-Z1-9]/g;
    return !pattern.test(v) || 'Можно использовать только русский алфавит'
  },
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Неверно указан адрес почты'
  },
}