<template>
  <LayoutSingle
    :header="true"
    :footer="true"
    :title="'Заголовок'"
    :editBtnText="'Сохранить'"
    :max-width="1303"
    @send-data="sendData"
  >
    <template #contactComponent>
      <!--              :addNotification="addNotification"-->
      <div style="display: flex; flex-direction: row">
        <Contact
          :config="config"
          :header="'Контактная информация представителя центра ответственности, ответственного за формирование предложений о корректировке проекта контрольных цифр приёма.'"
          :contact="mainContact"
          :getContact="getContact"
          :setContact="changeContact"
          :isAdminGroup="false"
          :isDisabledEnter="false"
          :isMain="isMain"
          :updatedData="updatedData"
        />
        <div class="stipendiatContacts__checkbox-container">
          <v-checkbox
            off-icon
            v-model="val1"
            label="Всероссийский открытый конкурс для назначения стипендий Президента Российской Федерации для обучающихся за рубежом"
          ></v-checkbox>
          <v-checkbox
            off-icon
            v-model="val2"
            label="Представление списков претендентов на стипендии Президента РФ и Правительства РФ"
          ></v-checkbox>
          <v-checkbox
            off-icon
            v-model="val3"
            label="Представление списков претендентов  на стипендии  Правительства РФ (СПО)"
          ></v-checkbox>
          <v-checkbox
            off-icon
            v-model="val4"
            label="Представление списков претендентов на стипендии Президента РФ и Правительства РФ (для ФОИВов)"
          ></v-checkbox>
          <v-checkbox
            off-icon
            v-model="val5"
            label="Всероссийский открытый конкурс для назначения именных стипендий"
          ></v-checkbox>
          <v-checkbox
            off-icon
            v-model="val6"
            label=" Всероссийский открытый конкурс для назначения именных стипендий для лиц, относящихся к коренным малочисленным народам Севера, Сибири и Дальнего Востока Российской Федерации"
          ></v-checkbox>
        </div>
      </div>
    </template>
  </LayoutSingle>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import {Contact, LayoutSingle} from "@frontenddevelopers/ined-components/src/lib";
import contacts from "@/utils/contacts";

export default {
  name: "ContactEditRights",
  components: {Contact, LayoutSingle},
  props: {
    isMain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val1: false,
      val2: false,
      val3: false,
      val4: false,
      val5: false,
      val6: false,
      config: contacts,
      updatedData: false,
      mainContact: {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
      },
    }
  },
  computed: {
    ...mapGetters('checkFilled', {get_check_filled_status: 'get_check_filled_status'}),
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isAdmin: 'isAdmin',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
  },
  
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('notifications', {clearNotifications: 'clearNotifications'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    
    async getContact() {
      let uri;
      if (!this.isRegion) {
        uri = `https://spck.test.ined.ru/api/person/official/getAllFoivId?founderId=${this.getUser.founderID}`
        if (!this.isMain) {
          uri = `https://spck.test.ined.ru/api/person/direct/getAllFoivId?founderId=${this.getUser.founderID}`
        }
      } else {
        uri = `https://spck.test.ined.ru/api/person/official/getAllRegionId?regionId=${this.getUser.regionID}`
        if (!this.isMain) {
          uri = `https://spck.test.ined.ru/api/person/direct/getAllFoivId?founderId=${this.getUser.founderID}`
        }
      }
      
      // if (this.isRegion) uri = `https://spck.test.ined.ru/api/person/official/getAllRegionId?regionId=${this.getUser.regionID}`
      const res = await request({
        endpoint: uri,
        method: 'get',
        token: `${this.getAuthData.token}`
      })
      await this.clearNotifications();
      // console.log(res.data[0]);
      if (res.status === 200 && res.data) {
        // const bufRes = res.data;
        // for (const key of Object.keys(res.data)) {
        //   bufRes[key] = bufRes[key] === null ? '' : bufRes[key]
        // }
        if (this.isMain) { // основной контакт
          this.mainContact = res.data
        } else { // массив вторичных контактов
          this.mainContact = res.data.sort((a, b) => a.personId - b.personId)[0]
        }
        // this.mainContact = bufRes
        // await this.fetchCheckFilled({mainContact: this.mainContact, contacts: this.contacts})
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных',
            // notificationMessage: 'Ошибка получения данных ответственного должностного лица высшего органа исполнительной власти',
            timeout: 5000
          })
      }
    },
    
    async changeContact(obj) {
      let uri = `https://spck.test.ined.ru/api/person/official/${obj?.personId ? 'update' : 'add'}`
      if (!this.isMain) {
        uri = `https://spck.test.ined.ru/api/person/direct/${obj?.personId ? 'update' : 'add'}`
      }
      
      const res = await request({
        // endpoint: `/api/person/official/${obj?.personId ? 'update' : 'add'}`,
        endpoint: uri,
        method: 'post',
        token: `${this.getAuthData.token}`,
        body: {
          ...obj,
          founderId: this.getUser.founderID,
          regionId: this.getUser.regionID,
          personId: obj?.personId,
        },
      })
      if (res.status === 200 && res.data) {
        this.mainContact = res.data
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного должностного лица высшего органа исполнительной власти',
          timeout: 5000
        })
      }
    },
    
    sendData(e) {
      this.updatedData = e;
    }
  },
}
</script>

<style lang="scss">
.v-input--checkbox {
  margin: 0 0 12px 15px;
  padding: 0;
  
  i {
    background: #E3EFFA;
    border-radius: 5px;
    border: none;
  }
  
  .v-input--selection-controls__ripple {
    cursor: url(../../assets/icons/cursor.svg), auto !important;
  }
  
  label {
    cursor: default !important;
  }
  
  .v-input--selection-controls__input {
    cursor: default !important;
  }
  
  .v-input__slot {
    cursor: default !important;
    margin-bottom: 0;
  }
  
  .v-input__control {
    .v-messages.theme--light {
      display: none;
    }
  }
}
</style>